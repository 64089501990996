import Vue from 'vue'
import App from './App.vue'
import store from './store'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import BootstrapVue from 'bootstrap-vue'
import VueI18n from 'vue-i18n'
import i18n from './i18n'
import Vuelidate from 'vuelidate'
import VueQrcode from '@chenfengyuan/vue-qrcode'
import './modules/removeRobotsTag';
import './modules/removeOgTags';
import './modules/removeTitleTag';
// import VueSentry from 'vue2-sentry'

Vue.component(VueQrcode.name, VueQrcode)

Vue.use(VueRouter)
Vue.use(VueMeta)
Vue.use(VueI18n)
Vue.use(BootstrapVue)
Vue.use(Vuelidate)
// Vue.use(VueSentry, {
//   protocol: 'https', // Optional: default is https
//   key: '',
//   server: '',
//   project: '',
//   config: {} // Optional: custom config
// })

Vue.config.productionTip = true

import routes from './routes'

const router = new VueRouter({
  mode: 'history',
  routes, // short for `routes: routes`
})

new Vue({
  store,
  i18n,
  router,
  render: h => h(App),
}).$mount('#ecasenkaDetail')
